import "./HeroSection.scss";

import React from "react";
import Img from "gatsby-image";

interface HeroSectionProps {
  heroTitle: string;
  heroTitleStyle?: object;
  heroImage?: object;
  heroDescription: string[];
  heroDescriptionStyle?: object;
  heroLogo?: object;
  heroLogoClass?: string;
}

export const HeroSection = ({
  heroTitle,
  heroTitleStyle,
  heroImage,
  heroDescription,
  heroDescriptionStyle,
  heroLogo,
  heroLogoClass,
}: HeroSectionProps) => {
  return (
    <div className="hero-section">
      <div className="grey">
        <h3 style={heroTitleStyle ? heroTitleStyle : {}}>{heroTitle}</h3>
        <Img
          fixed={heroImage}
          objectFit="contain"
          alt="Medrefer app"
          imgStyle={{ objectFit: "contain" }}
        />
      </div>
      <div
        className="white"
        style={heroDescriptionStyle ? heroDescriptionStyle : {}}
      >
        <div className="hero-desc">
          {heroDescription.map((desc) => (
            <h4 style={{ paddingTop: "10px" }}>{desc}</h4>
          ))}
        </div>
        <Img
          fixed={heroLogo}
          objectFit="contain"
          alt="Medrefer app"
          className={heroLogoClass}
          imgStyle={{ objectFit: "contain" }}
        />
      </div>
    </div>
  );
};
