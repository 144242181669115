import "./Banner.scss";

import React from "react";

interface BannerProps {
  title: string;
  subtitle: string;
}

export const Banner = ({ title, subtitle }: BannerProps) => {
  return (
    <div className="banner">
      <h2>{title}</h2>
      <p>{subtitle}</p>
    </div>
  );
};
