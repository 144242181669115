import "./CaseStudy.scss";

import React from "react";
import { ContinueReading } from "../../components/ContinueReading/ContinueReading";
interface CaseStudyProps {
  title: string;
  subtitle: string;
  body: Element;
}

export const CaseStudy = ({ title, subtitle, body }: CaseStudyProps) => {
  return (
    <div className="sections">
      <section className="case-study">
        {title && (
          <h1 className="red-dot" style={{ marginBottom: "25px" }}>
            {title}
          </h1>
        )}
        {subtitle && <h2>{subtitle}</h2>}
        {body}
        <span className="page-subtitle">Case study</span>
        <ContinueReading nextSection={"Portfolio"} link="portfolio" />
      </section>
    </div>
  );
};
