import "./CaseStudyDetails.scss";

import React from "react";

interface CaseStudyDetailsProps {
  title: string;
  image: object;
}

export const CaseStudyDetails = ({ title, image }: CaseStudyDetailsProps) => {
  return (
    <div className="case-study-details">
      <h2>{title}</h2>
      {image}
    </div>
  );
};
