import "./ColumnsSection.scss";

import React from "react";
import Img from "gatsby-image";

interface ColumnProps {
  icon?: object;
  title?: string;
  subtitle?: string;
  description: string[];
  image: object;
}

interface ColumnsSectionProps {
  columns: ColumnProps[];
  customClass?: string;
}

export const ColumnsSection = ({
  columns,
  customClass,
}: ColumnsSectionProps) => {
  return (
    <div className={`columns-section ${customClass ? customClass : ""}`}>
      {columns.map((column) => {
        return (
          <div className="row">
            <div className="text">
              {column.icon && column.icon}
              {column.title && <h3>{column.title}</h3>}
              {column.subtitle && <h4>{column.subtitle}</h4>}
              {column.description &&
                column.description.map((desc) => <p>{desc}</p>)}
            </div>
            <div className="image">
              <Img
                fixed={column.image}
                objectFit="contain"
                alt="Medrefer app"
                imgStyle={{ objectFit: "contain" }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
