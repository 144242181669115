import React, { ReactElement } from "react";
import Seo from "../../../components/Seo/seo";
import {
  HeroSection,
  ColumnsSection,
  Banner,
  CaseStudyDetails,
  CaseStudy,
} from "../../../components/CaseStudy";
import CaptioDetails from "../../../assets/imgs/case-study/captio/Details.svg";
import FullScreenIcon from "../../../assets/imgs/case-study/captio/icons/FullScreenIcon.svg";
import HalfScreenIcon from "../../../assets/imgs/case-study/captio/icons/HalfScreenIcon.svg";
import MinimizedIcon from "../../../assets/imgs/case-study/captio/icons/MinimizedIcon.svg";
import AdjustFontIcon from "../../../assets/imgs/case-study/captio/icons/AdjustFontIcon.svg";
import { graphql } from "gatsby";

export default function captio({ data }): ReactElement {
  const {
    heroImage,
    heroLogo,
    howItWorks,
    challange,
    solution,
    fullscreen,
    halfscreen,
    minimized,
    adjustfont,
  } = data;

  return (
    <div className="container">
      <Seo title={"Captio"} />
      <CaseStudy
        title="Video-chat app"
        body={
          <>
            <HeroSection
              heroTitle="No limits on communication with the world"
              heroDescription={[
                "Captio, a video-chat with real-time transcription for easy communication. Designed for people with hearing impairments.",
              ]}
              heroImage={heroImage.childImageSharp.fixed}
              heroLogo={heroLogo.childImageSharp.fixed}
            />
            <ColumnsSection
              customClass="captio"
              columns={[
                {
                  title: "How it works",
                  description: [
                    "Captio, a video-chat with real-time transcription for easy communication. Designed for people with hearing impairments.",
                  ],
                  image: howItWorks.childImageSharp.fixed,
                },
                {
                  title: "What was the challenge?",
                  description: [
                    "An estimated 466 million people worldwide – 5.5% of the population – have disabling hearing loss, and this number is expected to rise to 1 in 4 by 2050. Today, 34 million children have deafness or hearing loss, of which 60% of cases are due to preventable causes.",
                  ],
                  image: challange.childImageSharp.fixed,
                },
                {
                  title: "Solution",
                  description: [
                    "Our team responds to the needs of all those who wish to improve the quality of their remote communication. We have developed a solution that uses the latest technology to facilitate communication without compromising the quality of face-to-face exchanges.",
                  ],
                  image: solution.childImageSharp.fixed,
                },
              ]}
            />
            <ColumnsSection
              customClass="white-bg reverse flex captio mb0"
              columns={[
                {
                  icon: <FullScreenIcon />,
                  subtitle: "Full screen video-conversation",
                  description: [
                    "If you wish to imitate a face-to-face conversation, use the full-screen mode.",
                  ],
                  image: fullscreen.childImageSharp.fixed,
                },
                {
                  icon: <HalfScreenIcon />,
                  subtitle: "Half screen video-conversation",
                  description: [
                    "In this mode there is a separate place for chat history. Scroll the text if you want to make notes from the meeting.",
                  ],
                  image: halfscreen.childImageSharp.fixed,
                },
                {
                  icon: <MinimizedIcon />,
                  subtitle: "Minimized video-conversation",
                  description: [
                    "When clarity of the text is the key, you can minimize the video screen. In this option, you will still see your interlocutor in the upper corner of the screen.",
                  ],
                  image: minimized.childImageSharp.fixed,
                },
                {
                  icon: <AdjustFontIcon />,
                  subtitle: "Adjust Font",
                  description: [
                    "Choose the font style and size, as well as contrast, so that you can see the text clearly. You also have the option of fonts for dyslexics.",
                  ],
                  image: adjustfont.childImageSharp.fixed,
                },
              ]}
            />
            <Banner
              title="What makes us different?"
              subtitle="This is the first face-to-face remote communication service designed for people with hearing impairments, without the need to use sign language interpreting."
            />
            <CaseStudyDetails
              title="Captio in details"
              image={<CaptioDetails />}
            />
          </>
        }
      />
    </div>
  );
}

export const query = graphql`
  query {
    heroImage: file(
      relativePath: { eq: "imgs/case-study/captio/CaptioHero.png" }
    ) {
      childImageSharp {
        fixed(width: 1080, height: 700, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    heroLogo: file(
      relativePath: { eq: "imgs/case-study/captio/CaptioLogo.png" }
    ) {
      childImageSharp {
        fixed(width: 204, height: 71, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    howItWorks: file(
      relativePath: { eq: "imgs/case-study/captio/howitworks.png" }
    ) {
      childImageSharp {
        fixed(width: 523, height: 456, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    challange: file(
      relativePath: { eq: "imgs/case-study/captio/challange.png" }
    ) {
      childImageSharp {
        fixed(width: 523, height: 515, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    solution: file(
      relativePath: { eq: "imgs/case-study/captio/solution.png" }
    ) {
      childImageSharp {
        fixed(width: 523, height: 420, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fullscreen: file(
      relativePath: { eq: "imgs/case-study/captio/fullscreen.png" }
    ) {
      childImageSharp {
        fixed(width: 540, height: 360, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    halfscreen: file(
      relativePath: { eq: "imgs/case-study/captio/halfscreen.png" }
    ) {
      childImageSharp {
        fixed(width: 540, height: 360, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    minimized: file(
      relativePath: { eq: "imgs/case-study/captio/minimized.png" }
    ) {
      childImageSharp {
        fixed(width: 540, height: 360, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    adjustfont: file(
      relativePath: { eq: "imgs/case-study/captio/adjustfont.png" }
    ) {
      childImageSharp {
        fixed(width: 540, height: 360, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
